import { useRouter } from "next/router";
import { useState, type Dispatch, type SetStateAction } from "react";
import { getLocalDateString } from "~/lib/date";
import { formatTimeEstimate } from "~/lib/utils";
import { useListRefetch } from "~/providers/ListProvider";
import { useLogger } from "~/providers/LoggerProvider";
import { type List } from "~/server/api/model/list";
import Button from "./base/Button";
import SelectTrackerSimple from "./contact/shared/details/dependencies/SelectTrackerSimple";
import { useTracker } from "~/providers/TrackerProvider";
import { api } from "~/utils/api";
import { Skeleton } from "./lists/MainPage/SharedTable/utils";
import { Dialog } from "@headlessui/react";
export default function EvalSummaryModal({
  setShowModal,
  showModal,
  list,
  userId,
  teamAccountId,
  redirect,
  waitForTracker,
  onlyTriggerDeps
}: Readonly<{
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  list: List;
  userId: string | undefined;
  teamAccountId: string;
  redirect?: string;
  waitForTracker?: boolean;
  onlyTriggerDeps?: boolean;
}>) {
  const [isRunningEval, setIsRunningEval] = useState(false);
  const tracker = useTracker();
  const router = useRouter();
  const logger = useLogger();
  const listRefetch = useListRefetch();
  const {
    data: latestEvaluationsCountsRepo,
    isLoading: isLoadingRepos
  } = api.evaluation.latestEvaluationCountsByTypeAndListId.useQuery({
    listId: list.id,
    type: "repo",
    teamAccountId
  }, {
    refetchInterval: 1000 * 60
  });
  const {
    data: latestEvaluationsCountsUser,
    isLoading: isLoadingUsers
  } = api.evaluation.latestEvaluationCountsByTypeAndListId.useQuery({
    listId: list.id,
    type: "user",
    teamAccountId
  }, {
    refetchInterval: 1000 * 60
  });
  const isLoadingCounts = isLoadingRepos || isLoadingUsers;
  const {
    mutate: reEvaluateList
  } = api.evaluation.reEvaluateList.useMutation({
    onSuccess: () => {
      listRefetch().catch(err => logger.error(err, "EvalSummaryModal1.tsx"));
    }
  });
  const {
    mutate: triggerDepsEvals
  } = api.evaluation.triggerDepsEvals.useMutation({
    onSuccess: () => {
      listRefetch().catch(err => logger.error(err, "EvalSummaryModal2.tsx"));
    }
  });
  const totalRepos = latestEvaluationsCountsRepo?.totalEvalCount;
  const totalUsers = latestEvaluationsCountsUser?.totalEvalCount;
  const timeEstimate = tracker ? totalRepos ?? 0 * 120 + (totalUsers ?? 0) * 300 : totalRepos ?? 0 * 120 + (totalUsers ?? 0) * 120;
  const runEvaluations = () => {
    if (!list || !userId || !teamAccountId) return;
    setIsRunningEval(true);
    if (onlyTriggerDeps) {
      if (tracker?.id) triggerDepsEvals({
        listId: list.id,
        teamAccountId: teamAccountId,
        trackerId: tracker.id,
        userId
      });
    } else {
      reEvaluateList({
        listId: list.id,
        teamAccountId: teamAccountId
      });
    }
    setIsRunningEval(false);
    setShowModal(false);
    if (redirect) router.push(redirect).catch(err => logger.error(err, "EvalSummaryModal3.tsx"));
  };
  const disabledButton = waitForTracker && !tracker && isRunningEval;
  return <Dialog open={showModal} onClose={() => setShowModal(false)} className="relative z-50" data-sentry-element="Dialog" data-sentry-component="EvalSummaryModal" data-sentry-source-file="EvalSummaryModal.tsx">
      <div className="fixed inset-0 bg-black bg-opacity-50 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white px-16 py-4 text-left align-middle shadow-xl transition-all" data-sentry-element="unknown" data-sentry-source-file="EvalSummaryModal.tsx">
          <Dialog.Title className=" flex flex-col content-center items-center justify-center gap-2 text-lg leading-6 " data-sentry-element="unknown" data-sentry-source-file="EvalSummaryModal.tsx">
            <span className="py-2 text-2xl">Confirm Evaluations</span>
          </Dialog.Title>
          <Dialog.Description data-sentry-element="unknown" data-sentry-source-file="EvalSummaryModal.tsx">
            <div className="whitespace-normal px-12 pt-2">
              You are about to trigger a series of evaluations. Please review
              the data and confirm:
            </div>
          </Dialog.Description>
          <div>
            {" "}
            <div className="whitespace-normal px-12">
              <ul className="flex list-disc flex-col space-y-3 p-8 pl-4">
                <li>
                  {isLoadingCounts ? <Skeleton width="w-32" /> : <>
                      {totalRepos} Repositories and {totalUsers} Users
                    </>}
                </li>{" "}
                {/* with link to list */}
                <li>
                  From {getLocalDateString(list.startDate)} to{" "}
                  {getLocalDateString(list?.endDate ?? new Date())}
                </li>{" "}
                {/* link to modify */}
                <li>Estimated time: {formatTimeEstimate(timeEstimate)}</li>
                {tracker && <>
                    <li>
                      <span className="mr-4">Tracker:</span>

                      <SelectTrackerSimple />
                    </li>
                    <li>Searching for: {tracker.dependencies.join(", ")}</li>
                    <li>
                      Scanning files/path:{" "}
                      {tracker.defaultFileNames.concat(tracker.customFileNames).join(", ")}{" "}
                    </li>
                  </>}
                {/* with icon for info + link to modify */}
              </ul>
              <div>
                Please note that any further evaluations will be queued.
              </div>
            </div>
          </div>

          <div className="flex justify-end py-4">
            <Button disabled={disabledButton} onClick={runEvaluations} data-sentry-element="Button" data-sentry-source-file="EvalSummaryModal.tsx">
              Confirm
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>;
}