import { useTrackerName, useTracker } from "~/providers/TrackerProvider";
import Link from "next/link";
export default function SelectTrackerSimple() {
  const trackerName = useTrackerName();
  const tracker = useTracker();
  const listId = tracker?.listId;
  return <>
      {trackerName && <div className="relative w-fit">
          <Link href={`/lists/${listId}/dependency-tracking`} className="input-field cursor-normal flex w-full content-center items-center gap-2 px-2">
            {trackerName}
          </Link>
        </div>}
    </>;
}