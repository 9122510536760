import { type Row } from "@tanstack/react-table";
import { type TeamRenderContact } from "~/providers/ContactProvider";
import { useState, Component, type ReactNode } from "react";
import { Constants } from "@openqlabs/utils";
export const getPrimaryRepo = (rows: Row<TeamRenderContact>[]) => {
  const highestDepStatus = rows.reduce((acc, curr) => {
    const accumDepsStatus = acc.currentVal;
    const currDepsStatus = curr?.original?.dependencyStatusNumber ?? Constants.MAX_DEPS_NUMBER;
    if (currDepsStatus < accumDepsStatus) {
      return {
        rows: [curr],
        currentVal: currDepsStatus
      };
    }
    if (currDepsStatus === accumDepsStatus) {
      return {
        rows: [...acc.rows, curr],
        currentVal: currDepsStatus
      };
    }
    return acc;
  }, ({
    rows: [],
    currentVal: Constants.MAX_DEPS_NUMBER
  } as {
    rows: Row<TeamRenderContact>[];
    currentVal: number;
  }));
  const firstDepsRow = highestDepStatus.rows[0];
  const highestActivity = highestDepStatus.rows.reduce((acc, curr) => {
    const accumActivity = acc.original?.activity ?? 0;
    const currActivity = curr.original?.activity ?? 0;
    if (currActivity > accumActivity) {
      return curr;
    }
    return acc;
  }, firstDepsRow);
  return highestActivity;
};
export const getInfoValue = <T, GenericContact>(info: {
  row: Row<GenericContact>;
  getValue: () => T | undefined | null;
}) => {
  return info.row.original && (info.getValue() as T);
};
export const Skeleton = ({
  width = "w-full",
  height = "h-6"
}: {
  width?: string;
  height?: string;
}) => {
  return <div className={`flex ${width} animate-pulse px-2`} data-sentry-component="Skeleton" data-sentry-source-file="utils.tsx">
      <div className={`w-full rounded-md bg-gray-200/50 ${height}`}></div>
    </div>;
};
export const getScrollElement = (): Element | null => {
  const elem = document.getElementById("table-container");
  return elem;
};
export const stickToRight = "sticky left-0 top-0 z-20 overflow-hidden border-r border-gray-100 bg-gray-50 text-left";
export const TruncatedString = ({
  string,
  maxLength = 32
}: {
  string: string;
  maxLength?: number;
}) => {
  const [showExtended, setShowExtended] = useState(false);
  return <div className={`${showExtended ? "w-max" : "w-96"} flex justify-between gap-2 px-2 `} data-sentry-component="TruncatedString" data-sentry-source-file="utils.tsx">
      {string.length > maxLength ? <>
          <div>
            {showExtended ? string : string.slice(0, maxLength) + "..."}
          </div>
          <button className="text-xs text-blue-500 underline" onClick={() => setShowExtended(!showExtended)}>
            {showExtended ? "Show Less" : "Show More"}
          </button>
        </> : <div>{string}</div>}
    </div>;
};
interface State {
  hasError: boolean;
}
export class ErrorBoundary extends Component<{
  children: ReactNode | ReactNode[];
}, State> {
  constructor(props: {
    children: ReactNode | ReactNode[];
  }) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return {
      hasError: true
    };
  }
  componentDidCatch(error: Error) {
    console.error(error);
  }
  render() {
    if (this.state.hasError) {
      return <div data-sentry-component="ErrorBoundary" data-sentry-source-file="utils.tsx">Something went wrong.</div>;
    }
    return this.props.children;
  }
}